<template>
  <a-spin :spinning="spinShow">
    <form method="post" action="#" id="printJS-form">
      <h2 style="font-weight: bold" align="center">宁夏环保集团 农村污水清运档案</h2>
      <div align="center">
        <table border="1" cellspacing="0" cellpadding="0" width="1000">
          <tbody>
          <tr align="center" height="40px">
            <td>
              <span style="font-weight: bold">集污点编号</span>
            </td>
            <td>
              <span>{{formItem.no}}</span>
            </td>
            <td>
              <span style="font-weight: bold">农户姓名</span>
            </td>
            <td>
              <span>{{formItem.userName}}</span>
            </td>
            <td>
              <span style="font-weight: bold">电话号</span>
            </td>
            <td>
              <span>{{formItem.userPhone}}</span>
            </td>
          </tr>
          <tr align="center" height="40px">
            <td>
              <span style="font-weight: bold">容积</span>
            </td>
            <td>
              <span>{{formItem.bulk}} m³</span>
            </td>
            <td>
              <span style="font-weight: bold">地址</span>
            </td>
            <td colspan="4">
              <span>{{formItem.location}}</span>
            </td>
          </tr>
          <tr align="center" height="40px">
            <td>
              <span style="font-weight: bold">门牌号</span>
            </td>
            <td>
              <span>{{ formItem.houseNo ? formItem.houseNo : '暂无门牌号'}}</span>
            </td>
            <td>
              <span style="font-weight: bold">身份证号</span>
            </td>
            <td colspan="3">
              <span>{{ formItem.idcard ? formItem.idcard : '暂无身份证号'}}</span>
            </td>
          </tr>
          <tr align="center" height="40px">
            <td>
              <span style="font-weight: bold">院内外</span>
            </td>
            <td>
              <span>{{ formItem.positionType === 'nei' ? '院内' : '院外'}}</span>
            </td>
            <td>
              <span style="font-weight: bold">采集类型</span>
            </td>
            <td colspan="3">
              <span>{{ formItem.collectType === 'WECHAT' ? '微信采集': formItem.collectType === 'REGISTER'? '登记采集' : formItem.collectType === 'IMPORT'? '导入采集' : '其他途径'}}</span>
            </td>
          </tr>
          <tr align="center" height="40px">
            <td>
              <span style="font-weight: bold">备注信息</span>
            </td>
            <td colspan="5">
              <span>{{ formItem.matter}}</span>
            </td>
          </tr>
          <tr align="center" height="40px">
            <td colspan="6">
              <span style="font-weight: bold;font-size: 19px">{{year}}    年  清  运  记  录</span>
            </td>
          </tr>
          <tr align="center" height="40px">
            <td style="font-weight: bold"><span>序号</span></td>
            <td style="font-weight: bold"><span>清运时间</span></td>
            <td style="font-weight: bold"><span>趟数</span></td>
            <td style="font-weight: bold"><span>车牌号</span></td>
            <td style="font-weight: bold"><span>吨位</span></td>
            <td style="font-weight: bold"><span>清运司机</span></td>
          </tr>
          <template v-for="(item, index) in tableData">
            <tr align="center" height="40px">
              <td>
                <span>{{index + 1}}</span>
              </td>
              <td>
                <span>{{moment(item.qyTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
              </td>
              <td>
                <span>{{item.totalLoop}}</span>
              </td>
              <td>
                <span>{{item.carNo}}</span>
              </td>
              <td>
                <span>{{item.totalTon}}</span>
              </td>
              <td>
                <span>{{item.driver}}</span>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </form>
  </a-spin>
</template>

<script>
  import moment from 'moment'
  import printJS from 'print-js'
  import { gatherSpotFormVO } from './common/common'

  export default {
    name: 'DamageReportPrint',
    data () {
      return {
        spinShow: false,
        formItem: gatherSpotFormVO(),
        tableData: [],
        year: '',
      }
    },
    computed: {
      //获取当前单位id
      currentOrg () {
        return this.$store.getters.currentOrg
      }
    },
    methods: {
      moment,
      onPrint () {
        // 样式必须内敛，否则打印无效
        printJS({
          printable: 'printJS-form',
          type: 'html',
          ignoreElements: ['printBtn'],
          scanStyles: false,
          documentTitle: ''
        })
      },
      clearData () {
        this.spinShow = false
      }
    },
  }
</script>

<style scoped>
  @page {
    /*打印的页面定义样式*/
    size: auto;
    margin: 0;
  }
</style>