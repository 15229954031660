<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">清运预测</h1>
      <a-tabs :value="tabsValue" @change="callback" :tabBarStyle="{margin:0}">
        <a-tab-pane key="1" tab="在线预测">
        </a-tab-pane>
        <a-tab-pane key="2" tab="在线监测">
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-card v-if="tabsValue === '1'" :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="间隔日期">
              <a-radio-group @change="timeClick" v-model="timeType" button-style="solid">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="currentDay">当天</a-radio-button>
                <a-radio-button value="oneDay">1天</a-radio-button>
                <a-radio-button value="twoDay">2天</a-radio-button>
                <a-radio-button value="threeDay">3天</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="集污点名称">
              <a-input v-model="searchParams.name" placeholder="请填写集污点名称"/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table size="middle"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading"
               :pagination="pagination"
               @change="tableChange"
               :rowKey="(record) => record.id">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="days" slot-scope="text, record">
          <span>{{record.nextTime|calDays}}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleView(record)">报抽历史</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleReport(record)">登记</a>
        </template>
      </a-table>
    </a-card>
    <Monitor v-else></Monitor>
    <a-drawer
      title="集污点详细信息"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="1200px"
      :visible="detailDrawer"
      :mask="false">
      <spot-detail ref="viewRef"></spot-detail>
    </a-drawer>
    <a-modal
      title="报抽登记"
      :visible="addModal"
      width="1200px"
      :maskClosable="false"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <register-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></register-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { columns } from './common/common'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import moment from 'moment'
  import SpotDetail from '../gatherspot/Detail'
  import RegisterForm from '../reportrecord/report/Form'
  import Monitor from './Monitor'

  export default {
    name: 'LoopForecastList',
    components: { PageLayout, SpotDetail, RegisterForm, Monitor },
    mixins: [entityCRUDWithCopy],
    filters: {
      calDays (time) {
        return moment(time).diff(moment(), 'days')
      }
    },
    data () {
      return {
        dateSearch: null,
        entityBaseUrl: SERVICE_URLS.csgz.loopForecastApi,
        searchParams: {
          page: '0',
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: '', //集污点名称
          nextTime: '' //下次预测清运时间
        },
        initColumns: columns(),
        timeType: '',
        detailDrawer: false,
        addModal: false,
        tabsValue: '1'
      }
    },
    methods: {
      timeClick () {
        if (this.timeType === 'currentDay') {
          this.searchParams.nextTime = moment().format('YYYY-MM-DD')
        } else if (this.timeType === 'oneDay') {
          this.searchParams.nextTime = moment().add(1, 'days').format('YYYY-MM-DD')
        } else if (this.timeType === 'twoDay') {
          this.searchParams.nextTime = moment().add(2, 'days').format('YYYY-MM-DD')
        } else if (this.timeType === 'threeDay') {
          this.searchParams.nextTime = moment().add(3, 'days').format('YYYY-MM-DD')
        } else {
          this.searchParams.nextTime = ''
        }
        this.search()
      },
      detailClose () {
        this.detailDrawer = false
        //重置报抽查询条件按照编号查询
        this.$refs.viewRef.resetData()
      },
      handleView (record) {
        this.detailDrawer = true
        this.$nextTick(() => {
          //重置报抽查询条件按照编号查询
          this.$refs.viewRef.resetData()
          this.$refs.viewRef.loadData(record.gatherSpot.id)
        })
      },
      handleReport (record) {
        this.addModal = true
        this.$nextTick(() => {
          this.$refs.addRef.findGatherSpot(record.gatherSpot)
        })
      },
      //上报
      handleAddOk () {
        this.$refs['addRef'].submitAdd()
      },
      closeAddModal () {
        this.addModal = false
      },
      addSuccess () {
        this.addModal = false
        this.$message.success('登记成功！')
        this.search()
      },
      addError () {
        this.$message.warning('登记失败！')
        this.addModal = false
      },
      resetForm () {
        this.timeType = ''
        Object.assign(this.searchParams, this.initParams)
        this.search()
      },
      callback (key) {
        this.tabsValue = key
      }
    }
  }
</script>
